<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="标题：" prop="title"><el-input v-model="formData.title" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="报告时间：" prop="reportTime">
							<el-date-picker
								v-model="formData.reportTime"
								type="date"
								value-format="yyyy-MM-dd"
								placeholder="选择日期"
								:disabled="dialogObj.type == 0"
							></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="接收企业：" prop="entId">
							<el-select v-model="formData.entId" clearable :popper-append-to-body="false" placeholder="请选择" :disabled="dialogObj.type == 0" @change="entchange">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-show="showUnit">
					<el-col :span="24">
						<el-form-item label="所属设备：" prop="unitId" >
							<el-cascader
								ref="unit"
								v-model="formData.unitId"
								:options="unitList"
								:props="{ expandTrigger: 'hover', value: 'id' }"
								@change="handleChange"
							></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="24">
						<el-form-item label="备注："><el-input type="textarea" v-model="formData.remarks" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="文档附件：">
							<el-upload
								class="avatar-uploader"
								action=""
								:before-upload="BeforeUpload"
								:on-remove="handleRemove"
								:http-request="Upload"
								:file-list="fileList"
								accept=".pdf"
								:disabled="dialogObj.type == 0"
								:limit="1"
							>
								<el-button size="small" type="primary" :disabled="dialogObj.type == 0">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过10MB</div>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()" v-if="dialogObj.type != 0">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			ents: [],
			newFile: new FormData(),
			fileList: [],
			unitList: [],
			showUnit: false,
			formData: {
				unitId: ''
			},
			rules: {
				reportTime: [
					{
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}
				],
				entId: [
					{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}
				],
				unitId: [{ required: true, message: '请选择所属设备', trigger: 'change' }]
			}
		};
	},
	props: {},
	watch: {},
	methods: {
		//获取单体列表
		getunitList(e) {
			this.unitList = [];
			this.$get('/backend-api/web/monitor/tree?ent_id=' + e).then(res => {
				if (res.code == 1000) {
					this.showUnit = true;
					this.unitList = res.data[0].children;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		entchange(e) {
			this.getunitList(e);
		},
		handleChange(value) {
			let node = this.$refs.unit.getCheckedNodes();
			this.formData.unitId = node[0].value;
			this.formData.unitName = node[0].label;
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//打开弹窗
		open() {
			this.showUnit = false;
			this.fileList = [];
			this.newFile = new FormData();
			this.formData = {};
			this.get();
			this.getEnts();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		//上传前校验
		BeforeUpload(file) {
			if (this.fileList.length > 0) {
				this.$message.error('只能上传一份');
				return false;
			}
			// if(this.formData.attachment==undefined){
			// 	this.$message.error('只能上传一份');
			// 	return false;
			// }
			const isLt2M = file.size / 1024 / 1024 < 10;
			var filtype = file.name.substring(file.name.lastIndexOf('.') + 1);
			const extension = filtype === 'pdf';
			if (!extension) {
				this.$message.error('上传文件只能是 PDF 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传大小不能超过 10MB!');
			} else {
				if (file) {
					if (this.newFile.has('file')) {
						this.newFile.delete('file');
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		handleRemove(file, fileList) {
			this.formData.attachment = '';
			this.fileList = fileList;
		},
		//手动上传
		Upload() {
			this.loading = true;
			this.$uploadWithName(this.newFile)
				.then(res => {
					if (res.code == 1000) {
						this.formData.attachment = res.data;
						this.loading = false;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				})
				.catch(error => {
					this.$message.error(error);
				});
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				if (this.dialogObj.type == 0) {
					this.title = '查看';
				} else {
					this.title = '编辑';
				}
				this.$get('/base-api/report_health/get', {
					id: this.dialogObj.id
				}).then(res => {
					if (res.code == 1000) {
						this.getunitList(res.data.entId)
						this.formData = res.data;
						if (this.formData.attachment != '') {
							let filePath = this.formData.attachment;
							let index = filePath.lastIndexOf('/');
							this.fileList.push({
								name: filePath.substring(index + 1, filePath.length)
							});
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '上传报告';
			}
		},
		//提交表单
		submit() {
			let _this = this;
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.unitList = [];
					if (!this.formData.attachment || this.formData.attachment == '') {
						this.$message.error('请上传附件');
						return;
					}
					this.loading = true;
					if (this.dialogObj.type == 1) {
						this.$postData('/base-api/report_health/save-or-update', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else if (this.dialogObj.type == 2) {
						this.$postData('/base-api/report_health/save-or-update', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.unitList = [];
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}
</style>
fileList
