<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="10">
						<el-form-item label="接收企业：">
							<el-select v-model="searchParm.entId" clearable :popper-append-to-body="false"
								placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-row>
							<el-form-item label="报告时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.timeStart" value-format="yyyy-MM-dd"
										type="date" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;">
									<span>-</span>
								</el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.timeEnd" value-format="yyyy-MM-dd"
										type="date" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">
						上传报告
					</el-button>
					<!-- <el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数"
						@change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select> -->
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column  align="center" label="序号" show-overflow-tooltip width="80">
								<template slot-scope="scope">
									<div>{{scope.$index+1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="title" align="center" label="标题" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="reportTime" align="center" label="报告时间" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="entName" align="center" label="接收企业" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="remarks" align="center" label="备注" show-overflow-tooltip>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text" @click="preview(scope.row)">
										在线预览
									</el-button>
									<el-button type="text" @click="download(scope.row)">
										下载
									</el-button>
									<el-button type="text" @click="edit(scope.row)">
										编辑
									</el-button>
									<el-button type="text" @click="remove(scope.row)">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import smFooter from "@c/smFooter.vue"
	import edit from "./edit.vue"
	export default {
		components: {
			smCard,
			smFooter,
			edit
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 20,
					current: 1
				},
				ents: [],
				showEditDialog: false,
				editObj: {},
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/base-api/report_health/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//在线预览
			preview(row) {
				let path = row.attachment;
				window.open(this.photoURL + path);
			},
			download(row) {
				this.$get("/base-api/file/download", {
					path: row.attachment
				}).then((res) => {
					if (res.code == 1000) {
						var bstr = atob(res.data), //解析 base-64 编码的字符串
							n = bstr.length,
							u8arr = new Uint8Array(n); //创建初始化为0的，包含length个元素的无符号整型数组
						while (n--) {
							u8arr[n] = bstr.charCodeAt(n); //返回字符串第一个字符的 Unicode 编码
						}
						let index = row.attachment.lastIndexOf("/");
						let filename = row.attachment.substring(index + 1, row.attachment.length)
						const blob = new Blob([u8arr]); // 创建blob对象
						const elink = document.createElement("a"); // 创建的标签
						elink.download = filename;
						elink.style.display = "none";
						elink.href = URL.createObjectURL(blob); // 创建url
						document.body.appendChild(elink); // 把 创建的标签追加到body里
						elink.click();
						URL.revokeObjectURL(elink.href); // 释放URL 对象
						document.body.removeChild(elink); // 移除标签
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//删除
			remove(row) {
				this.$confirm("是否确认删除？", "删除健康状况报告", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/base-api/report_health/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//打开查看弹窗
			look(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.editObj["type"] = 0;
				this.showEditDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.editObj["type"] = 1;
				this.showEditDialog = true;
			},
			//打开新增弹窗
			insert() {
				this.editObj = {};
				this.editObj["type"] = 2;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
		},
		mounted() {
			this.list();
			this.getEnts();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
